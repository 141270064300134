import React from "react";
import { View, StyleSheet } from "react-native";
import UserContext from "../../context/UserContext";
import settings from "../../config/settings";
import Logger from "../utils/Logger";

class BaseController extends React.Component {
  static contextType = UserContext;

  state = {
    isMobile: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize = () => {
    if (window.innerWidth <= settings.mobileSize && !this.state.isMobile) {
      this.setState({ isMobile: true });
    } else if (window.innerWidth > settings.mobileSize && this.state.isMobile) {
      this.setState({ isMobile: false });
    }

    if (this.handleExtraResize) {
      this.handleExtraResize(window.innerHeight, window.innerWidth);
    }
  };
}

const styles = StyleSheet.create({
  container: {},
});

export default BaseController;
