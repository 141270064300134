import React from 'react';
import {View, StyleSheet} from 'react-native';
import colors from '../config/colors';
import NtText from './NtText';

function NtBorderTextView({children, title, style, containerStyle}) {
  return (
    <View style={[styles.container, style]}>
      <View style={[styles.innerContainer, containerStyle]}>{children}</View>
      <NtText style={styles.title}>{title}</NtText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
  },
  innerContainer: {
    marginTop: -3,
    width: '100%',
    borderWidth: 1,
    borderColor: colors.teal,
    borderRadius: 8,
    paddingBottom: 6,
  },
  title: {
    position: 'absolute',
    marginTop: -12,
    color: colors.blue,
    backgroundColor: 'white',
    paddingLeft: 10,
    paddingRight: 10,
  },
});

export default NtBorderTextView;
