import React, { useState } from "react";
import { useWindowDimensions } from "react-native";
import settings from "../config/settings";

export const useMobile = () => {
  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  // had to make this because of issues with swiper that it preloads the pages and then it will not refresh them...
  if (width <= settings.mobileSize && !isMobile) {
    setIsMobile(true);
  } else if (width > settings.mobileSize && isMobile) {
    setIsMobile(false);
  }

  return isMobile;
};
