import React from 'react';
import {View, StyleSheet, Text} from 'react-native';
import defaultStyle from '../config/styles';

function NtText({style, ...otherProbs}) {
  return <Text style={[defaultStyle.text, style]} {...otherProbs} />;
}

const styles = StyleSheet.create({
  text: {},
});

export default NtText;
