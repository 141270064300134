import React from "react";
import { ActivityIndicator, View, StyleSheet } from "react-native";

function NtActivityIndicator({
  color = "white",
  size = "large",
  containerStyle,
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <ActivityIndicator color={color} size={size} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtActivityIndicator;
