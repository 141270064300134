import React from "react";
import { View, StyleSheet } from "react-native";
import BaseController from "../../common/base/BaseController";
import { navigationRef } from "../../../App";

class RootController extends BaseController {
  state = {
    currentController: null,
    isMobile: false,
  };

  componentDidMount() {
    super.componentDidMount();
  }

  navigate = (name, params) => {
    navigationRef.current?.navigate(name, params);
  };

  handleNavPress = (route) => {
    this.navigate(route);
  };
}

export default RootController;
