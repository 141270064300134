import React from "react";
import { View, StyleSheet } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

function NtMaterialIcon({
  style,
  containerStyle,
  size = 20,
  color = "red",
  name = "home",
}) {
  return (
    <View style={[styles.container, containerStyle]}>
      <MaterialCommunityIcons
        style={style}
        name={name}
        size={size}
        color={color}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtMaterialIcon;
