import React from "react";
import { View, StyleSheet, TouchableOpacity } from "react-native";

function NtTouchableEffect({ style, children, onPress, ...otherProps }) {
  return (
    <TouchableOpacity
      style={[styles.container, style]}
      onPress={onPress}
      {...otherProps}
    >
      {children}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtTouchableEffect;
