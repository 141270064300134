import React from "react";
import { View, StyleSheet, Text } from "react-native";
import NtButton from "../../components/NtButton";
import colors from "../../config/colors";
import routes from "../../navigation/routes";

class NotFoundController extends React.Component {
  state = {};

  handleGoBack = () => {
    this.props.navigation.navigate(routes.marketing);
  };

  componentDidMount() {}

  render() {
    return (
      <View style={styles.container}>
        <NtButton
          containerStyle={styles.button}
          text={"Go Back"}
          icon={"chevron-left"}
          onPress={() => this.handleGoBack()}
        />
        <Text style={styles.error}>Error 404: Page Not Found</Text>
        <Text style={styles.message}>
          The system is unable to find the requested action '
          {this.props.route.path}'
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightest,
    padding: 40,
  },
  button: {
    width: 100,
    marginTop: 80,
  },
  error: {
    marginTop: 20,
    color: "red",
    fontSize: 26,
  },
  message: {
    marginTop: 8,
    color: colors.darkest,
    fontSize: 20,
    fontWeight: "300",
  },
});

export default NotFoundController;
